<template>
    <div class="">
        <div style="font-size: 0">
            <!-- <img class="topImgBg" src="@/assets/pc/banner.png" alt="" /> -->
            <div class="topImgBg">
                <div class="viewContent">
                    <img
                        class="logo"
                        src="@/assets/am/logo.png"
                        alt=""
                        srcset=""
                    />
                </div>
            </div>
        </div>
        <div class="container">
            <div class="contents">
                <div
                    class="contents-item"
                    v-for="(item, i) in contents"
                    :key="i"
                >
                    <img class="item-img" :src="item.icon" alt="" />
                    <div class="item-title">{{ item.title }}</div>
                    <div class="item-text">{{ item.text }}</div>
                </div>
            </div>
            <div class="graphic">
                <template v-for="(item, index) in graphics">
                    <div
                        v-if="item.textRight"
                        class="graphic-item"
                        :key="index"
                    >
                        <div class="graphic-item-content">
                            <div class="graphic-item-title">
                                <div>{{ item.num }}</div>
                                <div>
                                    {{ item.title }}
                                    <div class="graphic-item-text">
                                        {{ item.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img class="graphic-item-img" :src="item.img" alt="" />
                    </div>
                    <div v-else class="graphic-item" :key="index">
                        <img class="graphic-item-img" :src="item.img" alt="" />
                        <div class="graphic-item-content">
                            <div class="graphic-item-title">
                                <div>{{ item.num }}</div>
                                <div>
                                    {{ item.title }}
                                    <div class="graphic-item-text">
                                        {{ item.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="program-content">
                <div class="phone">
                    <el-carousel
                        ref="carousel"
                        arrow="never"
                        indicator-position="none"
                        height="882px"
                        :interval="5000"
                        @change="carouselChange"
                        class="carousel"
                    >
                        <el-carousel-item
                            v-for="(item, index) in miniprogramTabs"
                            :key="index"
                            class="carouselItem"
                        >
                            <img class="phone-item" :src="item.phone" alt="" />
                        </el-carousel-item>
                    </el-carousel>
                    <img class="phone-bg" src="@/assets/pc/phone.png" alt="" />
                </div>
                <div class="miniprogram">
                    <div style="text-align: right">
                        <div class="miniprogram-title">爱盟</div>
                        <div class="miniprogram-subtitle">
                            自助寻缘 让恋爱更简单
                        </div>
                        <div class="miniprogram-tip">
                            向前一步是爱情，扫码关注下方二维码，入住爱盟，发现更多优质异性
                        </div>
                    </div>
                    <img
                        class="minprogram-code"
                        src="@/assets/am/code.png"
                    />
                    <div class="miniprogram-tab">
                        <div
                            class="tab-item"
                            :class="
                                activation === index
                                    ? 'tab-item-activation'
                                    : ''
                            "
                            v-for="(item, index) in miniprogramTabs"
                            :key="index"
                            @click="setActiveItem(index)"
                        >
                            <img
                                class="tab-item-icon"
                                :src="
                                    activation === index
                                        ? item.icon2
                                        : item.icon
                                "
                                alt=""
                            />
                            <div class="tab-item-title">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contactUs">
            <div class="contactUs-content">
                <div>
                    <div class="ontactUs-title">联系我们</div>
                    <div class="ontactUs-text">
                        门店地址：深圳市宝安区新安街道上合社区33区大宝路83号美生慧谷科技园春谷2栋四楼
                    </div>
                    <div class="ontactUs-text">联系电话：13556860105</div>
                    <div class="ontactUs-text">官方微信：爱盟遇见对的人</div>
                    <div class="ontactUs-text">
                        版权所有：深圳市爱盟信息技术有限公司
                    </div>
                </div>
                <img
                    class="contactUs-store"
                    src="@/assets/pc/store.png"
                    alt=""
                />
            </div>
        </div>
        <footer class="footer">
            <!-- <div>
                <a
                    target="_blank"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002003404"
                    style="display: flex; text-decoration: none"
                    ><img src="@/assets/publicSecurity.png" />
                    <p style="color: #ffffff">川公网安备 51019002003404号</p></a
                >
            </div> -->
            <div>
                <a
                    target="view_window"
                    style="text-decoration: none"
                    href="http://beian.miit.gov.cn"
                >
                  <!-- 爱乐空间 -->
                    <!-- 蜀ICP备2020030076号 -->  
                    <!-- 爱渡 -->
                    <!-- 粤ICP备2021110723号-1 -->
                    <!-- 爱乐云 -->
                    <!-- 粤ICP备20069714号 -->
										粤ICP备2022087227号
                </a>
            </div>
            <!-- <div>增值电信业务经营许可证：川B2-20201191</div> -->
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            contents: [
                {
                    title: "认证门槛高",
                    icon: require("@/assets/pc/icon1.png"),
                    text: "身份、学历、工作认证\n为自己也为他人提供保障",
                },
                {
                    title: "定期活动",
                    icon: require("@/assets/pc/icon2.png"),
                    text: "丰富多彩的线下体验活动\n让爱在这里相遇",
                },
                {
                    title: "量身定制",
                    icon: require("@/assets/pc/icon3.png"),
                    text: "情感顾问深度1V1沟通\n为你制定脱单计划",
                },
                {
                    title: "成就自己",
                    icon: require("@/assets/pc/icon4.png"),
                    text: "丰富的线上恋爱课程\n助你成为更好的自己",
                },
                {
                    title: "全程服务",
                    icon: require("@/assets/pc/icon5.png"),
                    text: "1V1的恋爱私教全程跟进\n手把手教你恋爱技巧",
                },
            ],
            graphics: [
                {
                    textRight: true,
                    num: "一、",
                    title:
                        "这是一个值得信赖的平台，认证门槛高\n交友安全更有保障",
                    text:
                        "爱盟平台用户本科以上学历占比高达93.7%\n硕士以上学历占比37.3%,博士学历占比6.2%。",
                    img: require("@/assets/pc/graphic1.png"),
                },
                {
                    textRight: false,
                    num: "二、",
                    title: "这是一个专业的单身社交互动平台",
                    text:
                        "单身诊断、性格分析、恋爱类型分析为你量身定制脱单方案",
                    img: require("@/assets/pc/graphic2.png"),
                },
                {
                    textRight: true,
                    num: "三、",
                    title:
                        "这是一个专为单身打造的自然、轻松、多场景\n的相遇爱情的平台",
                    text: "丰富的线下活动，相遇不尴尬，让爱更简单",
                    img: require("@/assets/pc/graphic3.png"),
                },
                {
                    textRight: false,
                    num: "四、",
                    title: "在这里等待爱情到来的同时,也要帮助你成为更好的自己",
                    text:
                        "专业情感导师，全程为你提供高品质、全方位的高端婚恋服务",
                    img: require("@/assets/pc/graphic4.png"),
                },
                {
                    textRight: true,
                    num: "五、",
                    title: "1V1的恋爱私教全程跟进",
                    text: "督促你线上活跃，安排你线下见面，寻爱不可以懒惰",
                    img: require("@/assets/pc/graphic5.png"),
                },
            ],
            activation: 0,
            miniprogramTabs: [
                {
                    icon: require("@/assets/pc/tab1.png"),
                    icon2: require("@/assets/pc/tab11.png"),
                    phone: require("@/assets/pc/phone1.png"),
                    title: "精准匹配   心动异性",
                },
                {
                    icon: require("@/assets/pc/tab2.png"),
                    icon2: require("@/assets/pc/tab22.png"),
                    phone: require("@/assets/pc/phone2.png"),
                    title: "恋爱星球   展示自我",
                },
                {
                    icon: require("@/assets/pc/tab3.png"),
                    icon2: require("@/assets/pc/tab33.png"),
                    phone: require("@/assets/pc/phone3.png"),
                    title: "爱自己  成为更好的自己",
                },
                {
                    icon: require("@/assets/pc/tab4.png"),
                    icon2: require("@/assets/pc/tab44.png"),
                    phone: require("@/assets/xhx/phone4.png"),
                    title: "邂逅  自主选择各种活动",
                },
            ],
        };
    },
    created() {
        console.log(666);
    },
    methods: {
        carouselChange(e) {
            this.activation = e;
        },
        setActiveItem(index) {
            this.activation = index;
            this.$refs.carousel.setActiveItem(index);
        },
    },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>